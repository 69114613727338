import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from './history';
import app from './app/reducer';
import user from './user/reducer';
import orders from './orders/reducer';
import stage from './stage/reducer';

export default () => combineReducers({
  router: connectRouter(history),
  app,
  user,
  orders,
  stage,
});
