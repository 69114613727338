import update from 'immutability-helper';
import initialState from './constant';
import {
  APP_LOADING, APP_LOADING_OFF, APP_NOTIFY, APP_NOTIFY_OFF,
  APP_SET_FILTERS, APP_FETCH_STATUS_COUNT_START, APP_FETCH_STATUS_COUNT_END,
} from './action';

export default (state = initialState, action) => {
  switch (action.type) {
    case APP_LOADING:
      return update(state, {
        loading: {
          $set: true,
        },
      });
    case APP_LOADING_OFF:
      return update(state, {
        loading: {
          $set: false,
        },
      });
    case APP_NOTIFY:
      return update(state, {
        notification: {
          $set: {
            has: true,
            severity: action.severity || 'info',
            text: action.text,
          },
        },
      });
    case APP_NOTIFY_OFF:
      return update(state, {
        notification: {
          has: {
            $set: false,
          },
          text: {
            $set: null,
          },
        },
      });

    case APP_SET_FILTERS:
      return update(state, {
        filters: { $set: action.filters },
      });

    case APP_FETCH_STATUS_COUNT_START:
      return update(state, {
        loadingStatusCount: { $set: true },
      });

    case APP_FETCH_STATUS_COUNT_END:
      return update(state, {
        loadingStatusCount: { $set: false },
        statusCount: { $set: action.payload },
      });

    default:
      return state;
  }
};
