/* eslint-disable func-names */
import {
  takeEvery, all, fork,
} from 'redux-saga/effects';
import { ACTIVITIES_CREATE_START } from './action';
import { httpCreateActivity } from '../../api/activities';
import { handleError } from '../error';

function* createActivity() {
  yield takeEvery(ACTIVITIES_CREATE_START, function* ({ payload }) {
    try {
      yield httpCreateActivity(payload);
    } catch (e) {
      yield handleError(e);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createActivity),
  ]);
}
