/* eslint-disable import/prefer-default-export */
import { put } from 'redux-saga/effects';
import { get } from 'lodash';

export function* handleError(type, e) {
  const payload = { type, error: e.message || 'error.unknown' };

  if (!!e.response && e.response.data) {
    const { message, code } = e.response.data;
    if (message) {
      if (Array.isArray(message)) {
        payload.error = get(message, '0.messages.0.message');
      } else {
        payload.error += `: ${message}`;
      }
    }

    if (code) {
      payload.code = code;
    }
  }

  yield put(payload);
}
