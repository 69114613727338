/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
import React from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { get } from "lodash";
import notification from "antd/es/notification";
import Col from "antd/es/col";
import Radio from "antd/es/radio";
import Row from "antd/es/row";
import Typography from "antd/es/typography";
import Moment from "moment";
import { storage } from "../helpers/storage";
import { handleChangeOrderStatus } from "../helpers/order";
import { createActivity } from "../redux/activities/action";
import SimpleHeader from "../components/headers/SimpleHeader";
import InputNumber from "../components/ui/InputNumber";
import ActionButton from "../components/ui/ActionButton";
import { fetchOrders as fetchOrdersAction } from "../redux/orders/action";
import { httpFinishPacking } from "../api/orders";

import "../less/Shipping.less";

const { Paragraph } = Typography;

const MemoizedInputNumber = React.memo(InputNumber, (prevProps, nextProps) => {
  const prevValue = parseFloat(prevProps.value || "0");
  const nextValue = parseFloat(nextProps.value || "0");

  return prevValue === nextValue;
});

const ShippingScreen = ({ orders, fetchOrders, history, activityCreate }) => {
  const { id } = useParams();
  const [order, setOrder] = React.useState(get(orders, id, null));
  const [kolli, setKolli] = React.useState(0);
  const [weight, setWeight] = React.useState(
    get(order, "values.vekt", 0) / 1000
  );
  const [ship, setShip] = React.useState(true);
  const [identifier, setIdentifier] = React.useState(get(order, "customer_meta.0.kundenummer", 0));

  React.useEffect(() => {
    if (Object.keys(orders).length === 0) {
      fetchOrders(id, undefined);
    }
  }, []);

  React.useEffect(() => {
    const _order = get(orders, id, null);
    const vekt = get(_order, "values.vekt", 0) / 1000;
    const kundenummer = get(_order, "customer_meta.0.kundenummer", 0);
    setOrder(_order);
    setWeight(vekt);
    setIdentifier(kundenummer)
  }, [orders]);

  const handleShippingChange = (event) => setShip(event.target.value);
  
  const onPackingDone = (hasRest) => {
    activityCreate({
      creator: get(storage.get("user", {}), "username", ""),
      activity: "Status updated to Pakket",
      date: Moment().utc().toISOString(),
      order_sku: get(order, "values.number", ""),
    });

    if(hasRest) {
      history.push(`/rest/${id}`);
    } else {
      history.push("/kundeordre");
    }

  }

  const orderStatusActionHooks = (setLoading) => ({
    before: () => {
      setLoading(true);
    },
    after: () => {
      setLoading(false);
      history.push("/kundeordre");
    },
  });

  const handleValidate = async () => {
    const values = {
      kolli,
      vekt: weight,
      ship,
      identifier,
    };

    if(ship && !identifier) {
      notification.warning({
        message: "Kunde Id not specified and required for shipment",
      });
      return;
    }

    const { status, data } = await httpFinishPacking(id, values);
    if(status === 200) {
      notification.success({
        message: "Pakket",
      });
      const { hasRest } = (data || {}).data || {};
      onPackingDone(hasRest);
    }
  };

  

  return (
    <>
      <SimpleHeader>
        <Row>
          <Col xs={24}>
            <Paragraph>Ordrenummer #{get(order, "values.number", 0)}</Paragraph>
          </Col>
        </Row>
      </SimpleHeader>
      <Row className="shipping-global-container">
        <Row className="shipping-align-container">
          <Col xs={24} className="shipping-field-container">
            <Paragraph type="secondary" align="center" className="small-title">
              Kolli
            </Paragraph>
            {order !== null && (
              <MemoizedInputNumber
                decimals={0}
                value={kolli}
                onChange={setKolli}
                step={1}
                forceStep
              />
            )}
          </Col>

          <Col xs={24} className="shipping-field-container">
            <Paragraph type="secondary" align="center" className="small-title">
              Vekt (kg)
            </Paragraph>
            {order !== null && (
              <MemoizedInputNumber
                value={weight}
                onChange={setWeight}
                step={0.5}
                forceStep
              />
            )}
          </Col>

          <Col xs={24} className="shipping-field-container">
            <Paragraph type="secondary" align="center" className="small-title">
              Shipping
            </Paragraph>
            {order !== null && (
              <Radio.Group
                className="ship-radio-container"
                size="large"
                value={ship}
                buttonStyle="solid"
                onChange={handleShippingChange}
              >
                <Radio.Button
                  value={false}
                  style={
                    ship === false
                      ? { backgroundColor: "#f00", borderColor: "#f00" }
                      : {}
                  }
                >
                  Ikke send
                </Radio.Button>
                <Radio.Button value={true}>Send</Radio.Button>
              </Radio.Group>
            )}
          </Col>

          <Col xs={24} className="action-buttons">
            <Col xs={12}>
              <ActionButton
                size="large"
                type="danger"
                block
                action={(setLoading) => {
                  handleChangeOrderStatus(
                    "Ny",
                    id,
                    orderStatusActionHooks(setLoading)
                  );
                }}
              >
                Cancel
              </ActionButton>
            </Col>
            <Col xs={12}>
              <ActionButton
                size="large"
                type="primary"
                block
                action={(setLoading) => {
                  setLoading(true);
                  handleValidate()
                    /* .then((hasRest) => {
                      onPackingDone(hasRest);
                    }) */
                    .catch((e) => {
                      notification.error({
                        message: "Error on finalizing packing",
                        description: e.error || e.message,
                      });
                    })
                    .finally(() => setLoading(false));
                }}
              >
                Validate
              </ActionButton>
            </Col>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default connect(
  ({ orders }) => ({
    orders: orders.items || {},
  }),
  (dispatch) => ({
    fetchOrders: (...args) => dispatch(fetchOrdersAction(...args)),
    activityCreate: (...args) => dispatch(createActivity(...args)),
  })
)(withRouter(React.memo(ShippingScreen)));
