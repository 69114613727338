import React from 'react';
import Result from 'antd/es/result';
import Button from 'antd/es/button';

import { history } from '../../redux/history';

const NotFound = () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<Button type="primary" onClick={() => history.push('/kundeordre')}>Back Home</Button>}
  />
);

export default NotFound;
