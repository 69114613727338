/* eslint-disable no-console */
import { updatedOneOrder } from '../redux/orders/action';

export default {
  url: '/orders',
  listener: (dispatch) => ({ id, data }) => {
    dispatch(
      updatedOneOrder(id, data),
    );
    console.log('EVENT', data);
  },
};
