import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { history } from "./redux/history";
import store from "./redux/store";
import LoginScreen from "./screens/Login";
import DashboardScreen from "./screens/Dashboard";
import PackingScreen from "./screens/Packing";
import RestScreen from "./screens/Rest";
import ShippingScreen from "./screens/Shipping";
import NotFound404 from "./components/errors/404";
import * as realtimeBindings from "./realtime";
import extensions from "./realtime/extensions";
import UserLayout from "./components/layouts/UserLayout";

import "./less/App.less";

// realtime bindings
window.fayeClient = new window.Faye.Client(
  process.env.REACT_APP_REALTIME_SERVER || ""
);
window.fayeClient.addExtension(extensions.registerClientId);
window.fayeClient.addExtension(extensions.blockOwnUpdates);
// window.Faye.logger = window.console;
window.subscriptions = [];

Object.keys(realtimeBindings.default).forEach((key) => {
  window.subscriptions.push(
    null,
    window.fayeClient
      .subscribe(
        realtimeBindings.default[key].url,
        realtimeBindings.default[key].listener(store.dispatch)
      )
      .then(() => {
        // eslint-disable-next-line no-console
        console.log(`Subscribed to ${realtimeBindings.default[key].url}`);
      })
  );
});

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/kundeordre" />
          </Route>
          <Route path="/login">
            <LoginScreen />
          </Route>

          <Route exact path="/kundeordre">
            <UserLayout>
              <DashboardScreen />
            </UserLayout>
          </Route>
          <Route exact path="/pakk/:id">
            <UserLayout>
              <PackingScreen />
            </UserLayout>
          </Route>
          <Route exact path="/rest/:id">
            <UserLayout>
              <RestScreen />
            </UserLayout>
          </Route>
          <Route exact path="/shipping/:id">
            <UserLayout>
              <ShippingScreen />
            </UserLayout>
          </Route>

          <Route component={NotFound404} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
