/* eslint-disable func-names */
import {
  takeLatest, put, all, fork,
} from 'redux-saga/effects';
import { httpUserLogin } from '../../api/user';
import { handleError } from '../error';
import { USER_LOGIN_REQ, USER_LOGIN_DONE, USER_LOGIN_FAILED } from './action';
import { APP_FAILURE } from '../app/action';

export function* userLogin() {
  yield takeLatest(USER_LOGIN_REQ, function* ({ identifier, password }) {
    try {
      // process authentication
      const { jwt, user } = yield httpUserLogin(identifier, password)
        .then((response) => response.data);

      if (!user.id) {
        throw new Error('Invalid user data from server');
      }

      yield put({
        type: USER_LOGIN_DONE,
        user,
        token: jwt,
      });
    } catch (e) {
      yield put({
        type: USER_LOGIN_FAILED,
      });
      yield handleError(APP_FAILURE, e);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(userLogin),
  ]);
}
