/* eslint-disable no-case-declarations */
import update from 'immutability-helper';
import { get, keyBy } from 'lodash';
import initialState from './constant';
import {
  ORDER_FETCH_END, ORDER_FETCH_START, ORDER_UPDATED_ONE, ORDER_UPDATE_START, ORDER_UPDATE_END,
  ORDER_PRINT_START, ORDER_PRINT_END, REST_CREATE_START, REST_CREATE_END,
} from './action';

export default (state = initialState, action) => {
  switch (action.type) {
    case ORDER_FETCH_START:
      return update(state, {
        loading: {
          $set: (!state.items || state.items?.length === 0),
        },
      });
    case ORDER_FETCH_END:
      const fetchedOrder = get(action, 'payload', []);
      const items = keyBy(fetchedOrder, '_id');
      return update(state, {
        items: { $set: items },
        loading: { $set: false },
      });

    case ORDER_UPDATED_ONE:
      return update(state, {
        items: {
          [action.id]: {
            $set: get(action, 'payload', {}),
          },
        },
      });

    case ORDER_UPDATE_START:
      return update(state, {
        items: {
          [action.id]: {
            loading: { $set: true },
          },
        },
      });

    case ORDER_UPDATE_END:
      const item = get(state, ['items', action.id], null);
      if (!item) return state;

      const updateData = {
        items: {
          [action.id]: {
            loading: { $set: false },
          },
        },
      };
      const newStatus = get(action.body, '["$set"].status', null);
      if (newStatus) {
        updateData.items[action.id].values = { status: { $set: newStatus } };
      }
      return update(state, updateData);

    case ORDER_PRINT_START:
      return update(state, {
        items: {
          [action.id]: {
            printing: { $set: true },
          },
        },
      });

    case ORDER_PRINT_END:
      return update(state, {
        items: {
          [action.id]: {
            printing: { $set: false },
          },
        },
      });

    case REST_CREATE_START:
      return update(state, {
        creatingRest: { $set: true },
      });

    case REST_CREATE_END:
      return update(state, {
        creatingRest: { $set: false },
      });

    default:
      return state;
  }
};
