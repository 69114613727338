import { api } from "./client";

export const httpGetOrders = (filters = "values.status=Ny") =>
  api().get(`api/orders_optimized?${filters}`);

export const httpPutOrders = (id, body) => api().put(`api/orders/${id}`, body);

export const httpFindOrder = (id) => api().get(`api/orders/${id}`);

export const httpPrintOrderDocuments = (id, isNew = true) =>
  api().post(`api/orders/print_documents/${id}?new=${isNew ? 1 : 0}`);

export const httpPrintMsgLabel = (body) =>
  api().post(`api/orders/print_messages`, body);

export const httpUpdateAmounts = (id, body) =>
  api().post(`api/orders/amounts/${id}`, body);

export const httpGetStatusCount = () => api().get("api/orders/countstatus");

export const httpCreateRestOrder = (body) =>
  api().post("/api/orders/rest", body);

export const httpSetToPacked = (id) =>
  api().post(`/api/orders/packed/${id}`, {});

export const httpFinishPacking = (id, body) =>
  api().post(`/api/orders/finish_packing/${id}`, body);

export const httpSetPackingOrder = (id, body) =>
  api().post(`api/orders/packing_order/${id}`, body);
