export const ORDER_FETCH_START = '@orders/fetch_start';
export const ORDER_FETCH_END = '@orders/fetch_end';
export const ORDER_UPDATE_START = '@orders/update_start';
export const ORDER_UPDATE_END = '@orders/update_end';
export const ORDER_UPDATED_ONE = '@orders/update_one_order';
export const ORDER_UPDATE_AMOUNT_REQ = '@orders/amount/update_req';
export const ORDER_PRINT_START = '@orders/print_start';
export const ORDER_PRINT_END = '@orders/print_end';
export const REST_CREATE_START = '@orders/rest/create_start';
export const REST_CREATE_END = '@orders/rest/create_end';
export const ORDER_HTTP_SET_TO_PACKED = '@orders/http_setçorder_to_packed';
export const PACKING_SET_ORDER_REQ = '@orders/packing/set_packing_order_req';

export const fetchOrders = (id = null, filters = undefined, cb = () => {}) => ({
  type: ORDER_FETCH_START,
  id,
  filters,
  cb,
});

export const updateOrder = (id, body) => ({
  type: ORDER_UPDATE_START,
  id,
  body,
});

// Used when realtime is updating an order
export const updatedOneOrder = (id, order) => ({
  type: ORDER_UPDATED_ONE,
  id,
  payload: order,
});

export const updateOrderAmount = (id, order, cb) => ({
  type: ORDER_UPDATE_AMOUNT_REQ,
  id,
  order,
  cb
});

export const setPackingOrder = (id, order, cb) => ({
  type: PACKING_SET_ORDER_REQ,
  id,
  order,
  cb
});

export const printOrderExistingDocument = (id) => ({
  type: ORDER_PRINT_START,
  id,
});

export const createRest = (args, cb = null) => ({
  type: REST_CREATE_START,
  ...args,
  cb,
});

export const setToPacked = (id) => ({
  type: ORDER_HTTP_SET_TO_PACKED,
  id,
});
