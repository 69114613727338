import React, { useState } from 'react';
import Button from 'antd/es/button';

const handleAction = (action, setLoading) => (e) => {
  e.preventDefault();
  if (typeof action !== 'function') {
    return false;
  }
  action(setLoading);

  return true;
};

const ActionButton = ({ action, children, ...props }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      {...props}
      loading={loading}
      onClick={handleAction(action, setLoading)}
    >
      {children}
    </Button>
  );
};

export default ActionButton;
