import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import Col from "antd/es/col";
import Row from "antd/es/row";
import { get, chain /* mapValues */ } from "lodash";
import Modal from "antd/es/modal";
import { bindActionCreators } from "redux";
import Typography from "antd/es/typography";
import { CloseCircleOutlined } from "@ant-design/icons";
import Skeleton from "antd/es/skeleton";
import Button from "antd/es/button";
// import Moment from "moment";
import async from "async";
import { fetchOrders, setToPacked, 
  // updateOrderAmount, 
  setPackingOrder } from "../redux/orders/action";
import {
  requestPrint,
  requestPrintMsg,
  showSiderAction,
  hideSiderAction,
} from "../redux/stage/action";
// import UserLayout from '../components/layouts/UserLayout';
import SimpleHeader from "../components/headers/SimpleHeader";
import PackingList from "../components/ui/PackingList";
import { getLeveringsName, handleChangeOrderStatus } from "../helpers/order";
// import { STEPS } from '../redux/stage/constant';
import { createActivity } from "../redux/activities/action";
// import { storage } from "../helpers/storage";
import ActionButton from "../components/ui/ActionButton";

import "../less/Packing.less";

const { Paragraph } = Typography;

const PackingScreen = ({
  fetch,
  orders,
  loading,
  // isPrinting,
  // print,
  printMsg,
  history,
  // updateAmount,
  setPacking,
  // activityCreate,
  showSider,
  hideSider,
  siderShown,
}) => {
  const { id } = useParams();
  const [isLast, setLast] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const order = get(orders, id, false);

  // originaly we use line.amount, and will send delivered through /api/orders/amount/:id
  const [orderAmount, setOrderAmount] = useState(
    chain(get(order, "values.varelinjer", {}))
      .mapValues((line) => line.amount)
      .value()
  );
  const [originalAmount, setOriginalAmount] = useState(
    chain(get(order, "values.varelinjer", {}))
      .mapValues((line) => line.amount)
      .value()
  );
  const [amountChanged, setAmountChangeStatus] = useState(false);
  const orderStatusActionHooks = (setLoading) => ({
    before: () => {
      setLoading(true);
    },
    after: () => {
      setLoading(false);
      hideSider();
      history.push("/kundeordre");
    },
  });

  useEffect(() => {
    if (order === false) {
      fetch(id, undefined, () => {
        setAmountChangeStatus(false);
        setOrderAmount(
          chain(orders)
            .get(`${id}.values.varelinjer`, {})
            .mapValues((line) => line.amount)
            .value()
        );
      });
    }
  }, [id]);

  useEffect(() => {
    setOriginalAmount(
      chain(get(order, "values.varelinjer", {}))
        .mapValues((line) => line.amount)
        .value()
    );
  }, [orders, id]);

  const handleNextClick = () => {
    // no print for now
    // @deprecated this is handled on next step now
    // setPacked(id);
    /* activityCreate({
      creator: get(storage.get("user", {}), "username", ""),
      activity: "Status updated to Pakket",
      date: Moment().utc().toISOString(),
      order_sku: get(order, "values.number", ""),
    }); */
    
    setStateLoading(true);
    async.seq(
      (cb) => setPacking(order._id, { amountChanged, orderAmount }, cb),
      )(() => {
      setStateLoading(false);
      history.push(`/shipping/${id}`);
      /* if (amountChanged) {
        history.push(`/rest/${id}`);
      } else {
        history.push(`/shipping/${id}`);
      } */
    });

    // async.seq(
    //   // print order, if amount has been changed, update is handled
    //   // automatically
    //   (cb) => print(order._id, { amountChanged, orderAmount }, cb)
    // )(() => {
    //   // update this to its own method because it has a specific route in backend
    //   // due to new flows attached
    //   // @https://wausolutions.atlassian.net/browse/GU-158?atlOrigin=eyJpIjoiOGJiMDllY2YxYzdkNGU1NmFlNWU0ZmE0ZmMwMzU4MDIiLCJwIjoiaiJ9
    //   setPacked(id);
    //   // update(id, {
    //   //   $set: {
    //   //     status: 'Pakket',
    //   //     'values.status': 'Pakket',
    //   //     'values.pack_end': Moment().utc().toISOString(),
    //   //   },
    //   // });
    //   activityCreate({
    //     creator: get(storage.get("user", {}), "username", ""),
    //     activity: "Status updated to Pakket",
    //     date: Moment().utc().toISOString(),
    //     order_sku: get(order, "values.number", ""),
    //   });

    //   if (amountChanged) {
    //     history.push(`/rest/${id}`);
    //   } else {
    //     history.push(`/shipping/${id}`);
    //   }
    // });
  };

  return (
    <>
      <SimpleHeader>
        <Row>
          <Col xs={22}>
            <Paragraph ellipsis style={{ lineHeight: 2 }}>
              <span style={{ color: "#ffffff" }}>
                {get(order, "values.number", "")}
              </span>
              {">"} {getLeveringsName(get(order, "values", ""), true)}
            </Paragraph>
          </Col>
          <Col xs={2}>
            <Button
              type="text"
              icon={<CloseCircleOutlined />}
              style={{ color: "white" }}
              onClick={showSider}
            />
            <Modal
              visible={siderShown}
              closable
              onCancel={hideSider}
              footer={null}
              centered
            >
              <Row style={{ padding: "1rem" }} className="order-action-sider">
                <Col xs={24}>
                  <ActionButton
                    type="primary"
                    block
                    action={(setLoading) => {
                      handleChangeOrderStatus(
                        "Ny",
                        id,
                        orderStatusActionHooks(setLoading)
                      );
                    }}
                  >
                    Avbryt
                  </ActionButton>
                </Col>
                <Col xs={24}>
                  <ActionButton
                    type="primary"
                    block
                    action={(setLoading) => {
                      handleChangeOrderStatus(
                        "Rest",
                        id,
                        orderStatusActionHooks(setLoading)
                      );
                    }}
                  >
                    Rest
                  </ActionButton>
                </Col>
                <Col xs={24}>
                  <ActionButton
                    type="primary"
                    block
                    action={(setLoading) => {
                      handleChangeOrderStatus(
                        "Snooze",
                        id,
                        orderStatusActionHooks(setLoading)
                      );
                    }}
                  >
                    Snooze
                  </ActionButton>
                </Col>
              </Row>
            </Modal>
          </Col>
        </Row>
      </SimpleHeader>
      <Row style={{ paddingTop: "1rem" }}>
        <Col xs={24}>
          <Skeleton active loading={loading}>
            <PackingList
              order={order}
              onAmountChanged={(sku, value) => {
                setAmountChangeStatus(originalAmount[sku] !== value);
                setOrderAmount({
                  ...orderAmount,
                  [sku]: value,
                });
              }}
              printMsg={printMsg}
              onChange={(indexOpened) => {
                const lineKeys = Object.keys(
                  get(order, "values.varelinjer", {})
                );
                if (indexOpened >= lineKeys.length && !isLast) {
                  setLast(true);
                } else {
                  setLast(false);
                }
              }}
            />
          </Skeleton>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "2rem",
          textAlign: "right",
          marginBottom: "3.5rem",
        }}
      >
        <Col xs={24}>
          {isLast ? (
            <Button
              type="primary"
              size="large"
              loading={stateLoading}
              onClick={handleNextClick}
            >
              Neste
            </Button>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

PackingScreen.propTypes = {
  orders: PropTypes.shape({}).isRequired,
  fetch: PropTypes.func.isRequired,
  // updateAmount: PropTypes.func.isRequired,
  setPacking: PropTypes.func.isRequired,
  // update: PropTypes.func.isRequired,
  // activityCreate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  // isPrinting: PropTypes.bool.isRequired,
  // print: PropTypes.func.isRequired,
  printMsg: PropTypes.func.isRequired,
  showSider: PropTypes.func.isRequired,
  hideSider: PropTypes.func.isRequired,
  history: PropTypes.any.isRequired,
  // step: PropTypes.string.isRequired,
  siderShown: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ orders, app, stage }) => ({
  orders: orders.items || {},
  loading: app.loading,
  isPrinting: stage.loading,
  // step: stage.step,
  siderShown: stage.siderShown,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetch: fetchOrders,
      // update: updateOrder,
      print: requestPrint,
      printMsg: requestPrintMsg,
      // updateAmount: updateOrderAmount,
      setPacking: setPackingOrder,
      activityCreate: createActivity,
      setPacked: setToPacked,
      showSider: showSiderAction,
      hideSider: hideSiderAction,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(PackingScreen)));
