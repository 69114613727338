export default {
  loading: false,
  notification: {
    has: false,
    text: null,
    subText: null,
    severity: 'info',
  },
  filters: { Ny: true },
  statusCount: [],
  loadingStatusCount: false,
};
