/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Input from "antd/es/input";
import Button from "antd/es/button";

const InputNumber = ({
  value,
  onChange,
  style,
  size,
  step,
  forceStep,
  decimals,
  ...props
}) => {
  const [number, setNumber] = useState(value);
  const stepValue = step || 1;
  const force = forceStep !== undefined ? Boolean(forceStep) : false;
  const decimalsRounding = decimals !== undefined ? decimals : 2;
  const callOnChange = (...args) => {
    if (typeof onChange === "function") {
      onChange(...args);
    }
  };
  const updateDisplayNumber = (val) => {
    setNumber(val.toFixed(decimalsRounding));
  };
  const increase = () => {
    const current = parseFloat(number || "0");
    const newValue = force
      ? Math.round((current + stepValue) * 2) / 2
      : current + stepValue;
    updateDisplayNumber(newValue);
    callOnChange(newValue);
  };
  const decrease = () => {
    const current = parseFloat(number || "0");
    const newValue = force
      ? Math.round((current - stepValue) * 2) / 2
      : current - stepValue;
    updateDisplayNumber(newValue);
    callOnChange(newValue);
  };

  const buttonsStyles = {
    width: "50px",
    textAlign: "center",
  };

  return (
    <div
      className="ui-input-number"
      style={{ ...(style || {}), display: "flex" }}
      {...props}
    >
      <Button
        type="primary"
        size={size || "large"}
        onClick={() => decrease()}
        style={buttonsStyles}
      >
        -
      </Button>
      <Input
        type="text"
        value={number}
        min={0}
        style={{
          display: "block",
          flexGrow: 1,
          textAlign: "center",
          fontSize: "1.2rem",
          fontWeight: "bold",
        }}
        onChange={(v) => {
          if (v.target.value === "-") {
            updateDisplayNumber("-");
            return true;
          }
          setNumber(v.target.value);
          callOnChange(v.target.value ? parseFloat(v.target.value || "0") : 0);
          return true;
        }}
        onBlur={(v) => {
          updateDisplayNumber(
            v.target.value ? parseFloat(v.target.value || "0") : 0
          );
          callOnChange(v.target.value ? parseFloat(v.target.value || "0") : 0);
        }}
      />
      <Button
        type="primary"
        size={size || "large"}
        onClick={() => increase()}
        style={buttonsStyles}
      >
        +
      </Button>
    </div>
  );
};

export default InputNumber;
