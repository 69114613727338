import { createStore, applyMiddleware, compose } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import appInitial from './app/constant';
import userInitial from './user/constant';
import ordersInitial from './orders/constant';
import createReducers from './reducers';
import rootSaga from './saga';
import reactRouterMiddleware from './history';
import GlobalMiddleware from './middleware';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

export const initialState = {
  app: appInitial,
  user: userInitial,
  orders: ordersInitial,
};

const middlewares = [
  // place here project-based middlewares
  GlobalMiddleware,

  // bootstraped middlewares
  thunk,
  sagaMiddleware,
  reactRouterMiddleware,
];

let c = compose;

if (process.env.NODE_ENV !== 'production') {
  middlewares.unshift(reduxImmutableStateInvariant());
  middlewares.push(logger);
  c = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || c;
}

const store = createStore(
  createReducers(),
  initialState,
  c(
    applyMiddleware(...middlewares),
  ),
);

// run the saga
sagaMiddleware.run(rootSaga);

export default store;
