export const APP_LOADING = '@app/loading';
export const APP_LOADING_OFF = '@app/loading/off';
export const APP_FAILURE = '@app/failure';
export const APP_NOTIFY = '@app/notifiy';
export const APP_NOTIFY_OFF = '@app/notifiy/off';
export const APP_SET_FILTERS = '@app/set_filters';
export const APP_FETCH_STATUS_COUNT_START = '@app/fetch_status_count_start';
export const APP_FETCH_STATUS_COUNT_END = '@app/fetch_status_count_end';

export const notify = (severity, text) => ({
  type: APP_NOTIFY,
  severity,
  text,
});

export const notifyError = (e) => notify('error', e.message);

export const hideNotification = () => ({
  type: APP_NOTIFY_OFF,
});

export const setFilters = (filters) => ({
  type: APP_SET_FILTERS,
  filters,
});

export const fetchStatusCount = () => ({
  type: APP_FETCH_STATUS_COUNT_START,
});
