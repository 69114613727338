/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import Collapse from "antd/es/collapse";
import { get, cloneDeep } from "lodash";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Button from "antd/es/button";
import Tag from "antd/es/tag";
import update from "immutability-helper";

import { isInnpakking } from "../../helpers/item";
import { decodeHtmlStr } from "../../helpers/strings";
import InputNumber from "./InputNumber";

const { Panel } = Collapse;

const removeTrailingSlash = (site) => site.replace(/\/$/, "");
const resolveImageUrl = (product) => {
  if(product.meta && product.meta.bilder) {
    return `${removeTrailingSlash(process.env.REACT_APP_BACKEND_URL)}/${product.meta.bilder}`;
  }
  return `${removeTrailingSlash(process.env.REACT_APP_BACKEND_URL)}/images/product/${product.sku}`
}
const addNumberOfPackedToOrder = (order) => {
  const newOrder = cloneDeep(order);
  Object.keys(get(newOrder, "values.varelinjer", {})).forEach((sku) => {
    newOrder.values.varelinjer[sku] = {
      ...newOrder.values.varelinjer[sku],
      __packed: newOrder.values.varelinjer[sku].amount,
    };
  });
  return newOrder;
};

const PackingList = ({ order, onChange, onAmountChanged, printMsg }) => {
  const [currentOpened, setOpened] = useState("0");
  const [_order, setOrder] = useState({});
  useEffect(() => {
    setOrder({ ...addNumberOfPackedToOrder(order) });
  }, [order]);

  const openNext = () => {
    const current = parseInt(currentOpened, 10);
    const nextIteration = current + 1;
    setOpened(nextIteration.toString());
    if (typeof onChange === "function") {
      onChange(nextIteration);
    }
  };

  const handlePackAction = (sku) => {
    const notes = get(_order, ['values', 'ordre', sku, 'wcgwp_notes', 'notes'], []);
    if(typeof printMsg === "function" && notes.length > 0) {
      printMsg(notes.map(m => m.message));
    }
    openNext();
  }

  useEffect(() => {
    setTimeout(() => {
      const currentActive = document.getElementsByClassName(
        "ant-collapse-item-active"
      );
      if (currentActive && currentActive.length > 0) {
        const scrollToView = (elem) =>
          elem.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        const img = currentActive[0]?.getElementsByTagName("img");
        if (img && img.length > 0) {
          if (img.complete) {
            scrollToView(currentActive[0]);
          } else {
            scrollToView(currentActive[0]);
          }
          img[0].onload = () => {
            scrollToView(currentActive[0]);
          };
        } else {
          scrollToView(currentActive[0]);
        }
      }
    }, 400);
  }, [currentOpened]);

  const updatePacked = (sku, value) => {
    setOrder(
      update(_order, {
        values: {
          varelinjer: {
            [sku]: {
              __packed: { $set: value },
            },
          },
        },
      })
    );

    if (typeof onAmountChanged === "function") {
      onAmountChanged(sku, value);
    }
  };

  const orderLinesSorted = Object.values(get(_order, "values.varelinjer", {}))
    .sort((a, b) => {
      let first = parseInt(
        (get(a, "meta.lokasjon_new", "0") || "").replace(/\D/gi, ""),
        10
      );
      let second = parseInt(
        (get(b, "meta.lokasjon_new", "0") || "").replace(/\D/gi, ""),
        10
      );
      
      // Set as 39999 so that Innpakking with lokasjon 40000 is below
      if (first === 0) {
        first = 39999;
      }

      if (second === 0) {
        second = 39999;
      }

      return first - second;
    })
    .map((p) => get(p, ["meta.sku"], get(p, ["id"], 0)));
  /* const metaProducts = Object.entries(get(_order, "values.varelinjer", {})).map(([k, p]) => { 
    const m = get(p, "meta", {}); 
    return { 
      ...m, 
      lokasjon_new: parseInt(
    (get(m, "lokasjon_new", "0") || "").replace(/\D/gi, ""),
    10
  )}});
  const orderLinesSorted = orderBy(metaProducts, 'lokasjon_new', "asc").map((p) => get(p, ["meta.sku"], get(p, ["id"], 0))); */

  const renderInnpakking = (product) => {
    const renderOneNote = (note) => {
      // const from = decodeHtmlStr(note.from || "");
      // const to = decodeHtmlStr(note.to || "");
      const message = decodeHtmlStr(note.message || "");

      return (
        <Col xs={24} className="innpakking-item">
          {/* <p>
            <strong>Fra :</strong> {from}
          </p>
          <p>
            <strong>Til :</strong> {to}
          </p> */}
          <p>
            <strong>Melding :</strong> {message}
          </p>
        </Col>
      );
    };

    const notes = (product.wcgwp_notes || { notes: [] }).notes || [];
    const notesElements = notes.map(renderOneNote);
    const forProduct = (
      <h3>{`For : ${(product.wcgwp_notes || { for: "UNKNOWN" }).for}`}</h3>
    );

    return (
      <Col xs={24} className="innpakking-container">
        {forProduct}
        {notesElements}
      </Col>
    );
  };

  return (
    <Collapse
      accordion
      defaultActiveKey={currentOpened}
      activeKey={currentOpened}
      onChange={(current) => {
        setOpened(current);
      }}
    >
      {orderLinesSorted.map((sku, index) => (
        <Panel
          header={
            <>
              <span className="packinglist-header-sku">{sku}</span>
              <span className="packinglist-header-name">
                {_order.values.varelinjer[sku].title}
              </span>
              <Tag
                className="packinglist-header-tag"
                type="primary"
                style={{ float: "right" }}
              >
                {get(_order, `values.varelinjer[${sku}].__packed`, 0)}
              </Tag>
            </>
          }
          key={index}
          showArrow={false}
        >
          <Row justify="center" align="middle">
            <Col xs={24} sm={24} md={12} lg={8}>
              {isInnpakking(_order.values.varelinjer[sku]) ? (
                renderInnpakking(_order.values.varelinjer[sku])
              ) : (
                <img
                  src={resolveImageUrl(_order.values.varelinjer[sku])}
                  alt={sku}
                  style={{
                    display: "block",
                    width: "90%",
                    margin: "auto",
                    borderRadius: "7px",
                    marginBottom: "1.5rem",
                    backgroundColor: "#f7f2ef",
                    borderColor: "#f7f2ef",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    minHeight: "200px",
                  }}
                />
              )}
              <InputNumber
                step={1}
                decimals={0}
                value={_order.values.varelinjer[sku].amount}
                style={{ width: "90%", margin: "auto", marginBottom: "1rem" }}
                onChange={(value) => updatePacked(sku, value)}
              />
              <Button
                type="primary"
                size="large"
                style={{
                  width: "90%",
                  margin: "auto",
                  display: "block",
                  marginBottom: "1rem",
                }}
                onClick={() => {
                  handlePackAction(sku);
                }}
              >
                Pack
              </Button>
            </Col>
          </Row>
        </Panel>
      ))}
    </Collapse>
  );
};

export default PackingList;
