/* eslint-disable import/prefer-default-export */
import * as axios from 'axios';
import { storage } from '../helpers/storage';

// create the REST API client to use overall system
export const api = () => {
  const API_URL = `${process.env.REACT_APP_BACKEND_URL}/`;

  const headers = {
    'Content-Type': 'application/json',
  };

  // inject token if user is already authenticated
  if (storage.get('user/authenticated', false)) {
    headers.Authorization = `Bearer ${storage.get('user/token')}`;
  }

  if (window.rt_clientId) {
    headers['X-Realtime-client'] = window.rt_clientId;
  }

  const client = axios.create({
    baseURL: API_URL,
    headers,
  });

  client.interceptors.response.use((response) => response, (error) => {
    // go to /login if it ever fail to authenticate with token
    if ([401, 403].includes(error.response.status)) {
      storage.flush();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  });

  return client;
};
