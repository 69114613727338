window.rt_clientId = null;

const registerClientId = {
  incoming(message, callback) {
    if (message.channel === '/meta/handshake' && message.successful === true) {
      window.rt_clientId = message.clientId;
    }
    callback(message);
  },
  outgoing(message, callback) {
    callback(message);
  },
};

const blockOwnUpdates = {
  incoming(message, callback) {
    if (
      message.channel === '/orders'
      && message.data.type === 'update'
      && message.data.by === window.rt_clientId
    ) {
      console.log('OWN EVENT', message);
      return false;
    }
    return callback(message);
  },
  outgoing(message, callback) {
    callback(message);
  },
};

export default {
  registerClientId,
  blockOwnUpdates,
};
