import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import Row from 'antd/es/row';
import { get, keyBy, mapValues } from 'lodash';
import Typography from 'antd/es/typography';
import Card from 'antd/es/card';
// import Button from 'antd/es/button';
import SimpleHeader from '../components/headers/SimpleHeader';
import { createRest } from '../redux/orders/action';
import { setStagedOrder, fetchOrder } from '../redux/stage/action';
import { getLeveringsName } from '../helpers/order';
// import { STEPS } from '../redux/stage/constant';
import InputNumber from '../components/ui/InputNumber';

import '../less/Rest.less';

const { Paragraph } = Typography;

const RestScreen = ({
  order, fetch, history, _loading, restCreate, creatingRest,
}) => {
  const { id } = useParams();
  useEffect(() => {
    // if (!order) {
    // console.log('================is fetching');
    fetch(id);
    // }
  }, []);

  let orderLinesWithDiff = keyBy(Object.values(get(order, ['values', 'varelinjer'], {}))
    .filter((value) => {
      const ordered = parseInt(get(value, ['amount'], '0'), 10);
      const delivered = parseInt(get(value, ['delivered'], '0'), 10);

      return (ordered - delivered) > 0;
    }), 'id');

  orderLinesWithDiff = mapValues(orderLinesWithDiff, (v) => {
    const ordered = parseInt(get(v, ['amount'], '0'), 10);
    const delivered = parseInt(get(v, ['delivered'], '0'), 10);

    return {
      id: v.id,
      title: v.title,
      count: ordered - delivered,
    };
  });

  const [amountsState, setAmounts] = useState(orderLinesWithDiff);
  useEffect(() => {
    setAmounts(orderLinesWithDiff);
  }, [order]);

  const renderSkeletons = () => Array(4).fill(1).map((k, i) => (
    <Card key={`skeleton-${i}`} style={{ width: '100%', height: '80px' }} className="rest-card" loading />
  ));

  const renderLoadedCards = () => Object.values(orderLinesWithDiff).map((o) => (
    <Card key={`loadedcard-${o.id}`} style={{ width: '100%' }} className="rest-card">
      <Row align="middle">
        <Col className="rest-card-number" xs={4}>{o.id}</Col>
        <Col xs={10} md={15}>
          {o.title}
        </Col>
        <Col xs={10} md={5}>
          <InputNumber
            value={
              o.count
            }
            onChange={(amount) => {
              setAmounts(update(amountsState, {
                [o.id]: {
                  count: { $set: amount },
                },
              }));
            }}
          />
        </Col>
      </Row>
    </Card>
  ));

  return (
    <>
      <SimpleHeader>
        <Paragraph ellipsis>
          <span style={{ color: '#ffffff' }}>{get(order, 'values.number', '')}</span>
          {'>'}
          {' '}
          {getLeveringsName(get(order, 'values', ''), true)}
        </Paragraph>
      </SimpleHeader>
      <Row style={{ paddingTop: '1rem' }}>
        <Col xs={24}>
          <h2 style={{ textAlign: 'center' }}>Vil du opprette restordre?</h2>
          {
            _loading ? renderSkeletons() : renderLoadedCards()
          }
        </Col>
      </Row>
      <Row style={{ marginTop: '2rem', textAlign: 'right' }}>
        <Col xs={12} style={{ textAlign: 'left' }}>
          <Popconfirm
            placement="topLeft"
            title="Er du sikker på at du ikke vil opprette restordre?"
            onConfirm={() => { history.push('/kundeordre'); }}
            onCancel={() => {}}
            okText="Ja"
            cancelText="Nei"
          >
            <Button type="primary" size="large" danger>Nei</Button>
          </Popconfirm>
        </Col>
        <Col xs={12}>
          <Button
            type="primary"
            size="large"
            loading={creatingRest}
            onClick={() => {
              restCreate({
                id: order._id,
                amounts: mapValues(amountsState, (v) => v.count),
              }, () => {
                history.push('/kundeordre');
              });
            }}
          >
            Opprett
          </Button>
        </Col>
      </Row>
    </>
  );
};

RestScreen.propTypes = {
  fetch: PropTypes.func.isRequired,
  restCreate: PropTypes.func.isRequired,
  setOrderOnStage: PropTypes.func.isRequired,
  order: PropTypes.shape({}).isRequired,
  _loading: PropTypes.bool.isRequired,
  creatingRest: PropTypes.bool.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default connect(
  ({ stage, orders }) => ({
    order: stage.order,
    _loading: stage.loading,
    creatingRest: orders.creatingRest,
  }),
  (dispatch) => ({
    ...bindActionCreators({
      fetch: fetchOrder,
      restCreate: createRest,
      setOrderOnStage: setStagedOrder,
    }, dispatch),
  }),
)(withRouter(RestScreen));
