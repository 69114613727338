export const USER_LOGIN_REQ = '@user/login/req';
export const USER_LOGIN_DONE = '@user/login/done';
export const USER_LOGIN_FAILED = '@user/login/failed';
export const USER_LOGOUT = '@user/logout';

export const userLogin = (identifier, password) => ({
  type: USER_LOGIN_REQ,
  identifier,
  password,
});

export const userSet = (user) => ({
  type: USER_LOGIN_DONE,
  user,
});

export const userLogout = () => ({
  type: USER_LOGOUT,
});
