import { all } from 'redux-saga/effects';
import userSaga from './user/saga';
import appSaga from './app/saga';
import ordersSaga from './orders/saga';
import stageSaga from './stage/saga';
import activitiesSaga from './activities/saga';

export default function* rootSaga() {
  yield all([
    appSaga(),
    userSaga(),
    ordersSaga(),
    stageSaga(),
    activitiesSaga(),
  ]);
}
