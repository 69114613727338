/* eslint-disable func-names */
import {
  takeLatest, all, fork, put, takeEvery,
} from 'redux-saga/effects';
import { get, has } from 'lodash';
import {
  ORDER_FETCH_START, ORDER_FETCH_END, ORDER_UPDATE_START, ORDER_UPDATE_END, ORDER_UPDATE_AMOUNT_REQ,
  ORDER_PRINT_START, ORDER_PRINT_END, REST_CREATE_START, REST_CREATE_END, ORDER_HTTP_SET_TO_PACKED,
  PACKING_SET_ORDER_REQ,
} from './action';
import { handleError } from '../error';
import { APP_FAILURE } from '../app/action';
import {
  httpGetOrders, httpFindOrder, httpPutOrders, httpUpdateAmounts,
  httpPrintOrderDocuments, httpCreateRestOrder, httpSetToPacked,
  httpSetPackingOrder,
} from '../../api/orders';

function* fetchOrders() {
  // debugger;
  yield takeLatest(ORDER_FETCH_START, function* (action) {
    try {
      const { data } = action.id
        ? yield httpFindOrder(action.id) : yield httpGetOrders(action.filters);
      const order = has(data, 'items') ? get(data, 'items') : [data];

      yield put({
        type: ORDER_FETCH_END,
        payload: order,
      });

      if (typeof action.cb === 'function') {
        action.cb();
      }
    } catch (e) {
      yield handleError(APP_FAILURE, e);
    }
  });
}

function* updateOrder() {
  yield takeEvery(ORDER_UPDATE_START, function* ({ id, body }) {
    try {
      if (!id) {
        throw new Error('id is required');
      }

      yield httpPutOrders(id, body);

      yield put({
        type: ORDER_UPDATE_END,
        id,
        body,
      });
    } catch (e) {
      yield handleError(APP_FAILURE, e);
    }
  });
}

function* updateAmountOrder() {
  yield takeLatest(ORDER_UPDATE_AMOUNT_REQ, function* ({ id, order, cb }) {
    try {
      if (!id) {
        throw new Error('id is required');
      }

      yield httpUpdateAmounts(id, { delivered: order });

      
      if (typeof cb === 'function') {
        cb();
      }
    } catch (e) {
      yield handleError(APP_FAILURE, e);
    }
  });
}

function* setPackingOrder() {
  yield takeLatest(PACKING_SET_ORDER_REQ, function* ({ id, order, cb }) {
    try {
      if (!id) {
        throw new Error('id is required');
      }

      const { amountChanged, orderAmount } = order;

      yield httpSetPackingOrder(id, { ordered: orderAmount, amountChanged });

      
      if (typeof cb === 'function') {
        cb();
      }
    } catch (e) {
      yield handleError(APP_FAILURE, e);
    }
  });
}

function* printOrderDocs() {
  yield takeEvery(ORDER_PRINT_START, function* ({ id }) {
    try {
      yield httpPrintOrderDocuments(id, false);
    } catch (e) {
      yield handleError(APP_FAILURE, e);
    } finally {
      yield put({ type: ORDER_PRINT_END, id });
    }
  });
}

function* handleRestOrderCreation() {
  yield takeLatest(REST_CREATE_START, function* ({ id, amounts, cb }) {
    try {
      const res = yield httpCreateRestOrder({ id, amounts });

      if (typeof cb === 'function') {
        cb(res.data);
      }
    } catch (e) {
      yield handleError(APP_FAILURE, e);
    } finally {
      yield put({ type: REST_CREATE_END });
    }
  });
}

function* handleSetToPacked() {
  yield takeLatest(ORDER_HTTP_SET_TO_PACKED, function* ({ id }) {
    try {
      yield httpSetToPacked(id);
    } catch (e) {
      yield handleError(APP_FAILURE, e);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchOrders),
    fork(updateOrder),
    fork(updateAmountOrder),
    fork(printOrderDocs),
    fork(handleRestOrderCreation),
    fork(handleSetToPacked),
    fork(setPackingOrder),
  ]);
}
