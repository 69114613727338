import update from 'immutability-helper';
import initialState from './constant';
import {
  REQUEST_PRINTING_START, REQUEST_PRINTING_END, REINIT_STAGE, SET_STEP,
  SET_STAGED_ORDER, STAGE_FETCH_ORDER_START, STAGE_FETCH_ORDER_END,
  HIDE_SIDER, SHOW_SIDER,
} from './action';

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PRINTING_START:
      return update(state, {
        loading: { $set: true },
      });

    case SET_STAGED_ORDER:
      return update(state, {
        order: { $set: action.order },
      });

    case REQUEST_PRINTING_END:
      return update(state, {
        loading: { $set: false },
      });

    case SET_STEP:
      return update(state, {
        step: { $set: action.step },
      });

    case REINIT_STAGE:
      return { ...initialState };

    case STAGE_FETCH_ORDER_START:
      return update(state, {
        loading: { $set: true },
      });

    case STAGE_FETCH_ORDER_END:
      return update(state, {
        loading: { $set: false },
        order: { $set: action.order },
      });

    case SHOW_SIDER:
      return update(state, {
        siderShown: { $set: true },
      });

    case HIDE_SIDER:
      return update(state, {
        siderShown: { $set: false },
      });

    default:
      return state;
  }
};
