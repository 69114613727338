/* eslint-disable default-case */
import update from 'immutability-helper';
import initialState from './constant';
import { USER_LOGIN_DONE, USER_LOGOUT } from './action';

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return update(state, {
        $set: {
          authenticated: false,
        },
      });
    case USER_LOGIN_DONE:
      return update(state, {
        $set: {
          ...(action.user || {}),
          authenticated: true,
        },
      });
    default:
      return state;
  }
};
