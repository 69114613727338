/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TimeAgo from 'react-timeago';

export const DATE_FORMAT = 'DD.MM.YYYY';

const today = moment();
const yesterday = moment().subtract(1, 'day');

export const isToday = (date) => moment(date).isSame(today, 'day');
export const isYesterday = (date) => moment(date).isSame(yesterday, 'day');

export const DateValue = ({ value }) => {
  if (!value) {
    return null;
  }

  // interpret moment as local date
  const refDate = moment.utc(value).local();

  const isTodayValue = isToday(refDate);
  const isYesterdayValue = isYesterday(refDate);

  return isTodayValue
    ? <TimeAgo date={refDate.toDate()} title={refDate.format('DD.MM.YYYY HH:mm:ss')} />
    : (
      isYesterdayValue
        ? 'Yesterday'
        : refDate.format(DATE_FORMAT)
    );
};

DateValue.defaultProps = {
  value: undefined,
};

DateValue.propTypes = {
  value: PropTypes.string,
};
