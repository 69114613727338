import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import PropTypes from 'prop-types';

import '../../less/Header.less';

const SimpleHeader = ({ children, ...props }) => (
  <Row className="simple-header-container" {...props}>
    <Col xs={24}>
      {children}
    </Col>
  </Row>
);

SimpleHeader.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SimpleHeader;
