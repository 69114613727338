import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { flowRight, map } from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Drawer from "antd/es/drawer";

// import UserLayout from '../components/layouts/UserLayout';
import Order from "../components/ui/OrderItem";
import {
  fetchOrders,
  updateOrder,
  printOrderExistingDocument,
  updatedOneOrder as updatedOneOrderAction,
} from "../redux/orders/action";
import { createActivity } from "../redux/activities/action";
import { reinitStage } from "../redux/stage/action";
import { filterItems, handleChangeOrderStatus } from "../helpers/order";
import ActionButton from "../components/ui/ActionButton";

const DashboardComponent = ({
  items,
  loading,
  loadOrders,
  reinitTheStage,
  update,
  activityCreate,
  filters,
  print,
  updatedOneOrder,
}) => {
  const loadOrdersWithFilters = () => {
    const _filters = Object.keys(filters)
      .filter((key) => !!filters[key])
      .join(",");
    loadOrders(null, _filters.length > 0 ? `values.status=${_filters}` : "");
  };

  useEffect(() => {
    loadOrdersWithFilters();
    reinitTheStage();
  }, [filters]);

  // useEffect(() => {
  //   loadOrdersWithFilters();
  //   console.log('filters changed');
  // }, [filters]);

  const [details, setDetails] = useState({
    visible: false,
    order: {
      values: {},
    },
  });

  const hideDetails = () => {
    setDetails({
      visible: false,
      order: {
        values: {},
      },
    });
  };

  const handleDetailsClick = (orderValue) => () => {
    setDetails({
      visible: true,
      order: orderValue,
    });
  };

  // get the items filtered
  const filteredItems = filterItems(
    items || {},
    Object.keys(filters).filter((key) => !!filters[key])
  );

  // get the filtered items sorted aswell from oldest to newest
  // @see https://wausolutions.atlassian.net/browse/GU-223
  const renderableItems = map(filteredItems || {}, (order) => order).reverse();

  return (
    <>
      {loading
        ? Array(5)
            .fill()
            .map((_, i) => <Order.Skeleton key={`$.index.${i + 1}`} />)
        : map(renderableItems, (order) => (
            <Order.Item
              updateFn={update}
              createActFn={activityCreate}
              printFn={print}
              key={order.id}
              order={order}
              onDetails={handleDetailsClick}
            />
          ))}
      <Drawer
        placement="right"
        closable={false}
        onClose={hideDetails}
        visible={details.visible}
        getContainer={false}
        destroyOnClose
        className="drawer-order-details"
      >
        <Order.Details order={details.order} onClose={hideDetails} />
        <Row style={{ marginTop: "1rem" }}>
          <Col xs={24} style={{ marginBottom: "1rem" }}>
            <ActionButton
              type="primary"
              block
              action={(setLoading) => {
                const orderId = details.order.id || details.order._id;
                handleChangeOrderStatus("Rest", orderId, {
                  before: () => setLoading(true),
                  after: () => {
                    setLoading(false);
                    hideDetails();
                  },
                  done: ({ data }) => updatedOneOrder(orderId, data),
                  error: (exception) => console.log(exception),
                });
              }}
            >
              Rest
            </ActionButton>
          </Col>
          <Col xs={24}>
            <ActionButton
              type="primary"
              block
              action={(setLoading) => {
                const orderId = details.order.id || details.order._id;
                handleChangeOrderStatus("Snooze", orderId, {
                  before: () => setLoading(true),
                  after: () => {
                    setLoading(false);
                    hideDetails();
                  },
                  done: ({ data }) => updatedOneOrder(orderId, data),
                  error: (exception) => console.log(exception),
                });
              }}
            >
              Snooze
            </ActionButton>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

DashboardComponent.defaultProps = {
  items: {},
  loading: false,
};

DashboardComponent.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  loading: PropTypes.bool,
  loadOrders: PropTypes.func.isRequired,
  reinitTheStage: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  activityCreate: PropTypes.func.isRequired,
  print: PropTypes.func.isRequired,
  filters: PropTypes.shape({}).isRequired,
};

export default flowRight([
  connect(
    ({ orders, app }) => ({
      items: orders.items,
      loading: app.loading,
      filters: app.filters,
    }),
    (dispatch) =>
      bindActionCreators(
        {
          loadOrders: fetchOrders,
          reinitTheStage: reinitStage,
          update: updateOrder,
          activityCreate: createActivity,
          print: printOrderExistingDocument,
          updatedOneOrder: updatedOneOrderAction,
        },
        dispatch
      )
  ),
])(DashboardComponent);
