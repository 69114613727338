import React from 'react';
import PropTypes from 'prop-types';
import { map, get } from 'lodash';
import {
  PageHeader, Divider, Table, Typography,
} from 'antd';
import { getItemsCount } from '../../helpers/order';
import '../../less/OrderItem.less';

const OrderDetails = ({ order, onClose }) => (
  <div className="order-details">
    <PageHeader
      className="order-details-header"
      title={order.values.number}
      extra={[
        <div className="order-details-num">{getItemsCount(order.values)}</div>,
      ]}
      onBack={onClose}
    />
    <Divider />
    <Table
      rowKey="sku"
      showHeader={false}
      pagination={false}
      columns={[
        {
          dataIndex: 'sku',
          className: 'order-details-title',
          render: (sku, { title }) => (
            <span>
              {sku}
              <br />
              <em>{title}</em>
            </span>
          ),
        },
        { dataIndex: 'amount', render: (value) => `x ${value}`, align: 'right' },
      ]}
      dataSource={
        map((order.values || {}).ordre, (
          (row, sku) => ({ ...row, sku })
        ))
      }
      size="middle"
    />
    <br />
    {get(order, 'values.kommentar', '') !== ''
      ? (
        <Typography>
          <h5>Kommentar</h5>
          <Typography.Text><div dangerouslySetInnerHTML={{ __html: get(order, 'values.kommentar', '') }} /></Typography.Text>
        </Typography>
      ) : null}
  </div>
);

OrderDetails.propTypes = {
  order: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OrderDetails;
