import React from "react";
import PropTypes from "prop-types";
import Layout from "antd/es/layout";
import Card from "antd/es/card";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { flowRight } from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userLogin } from "../redux/user/action";
import config from "../config";

import "../less/Login.less";

const year = new Date().getFullYear();

const { Content, Footer } = Layout;

const LoginScreen = ({ loading, login }) => {
  const [form] = Form.useForm();

  const btnAttrs = {
    disabled: loading,
  };

  const handleFinish = (values) => {
    login(values.username, values.password);
  };

  return (
    <Layout className="login-layout">
      <Content>
        <Row justify="center" align="middle" className="login-wrapper">
          <Col xs={24} md={5}>
            <img
              src={config.logo}
              alt="gledesgaver-logo"
              className="login-logo"
            />
            <Card className="login-card">
              <Form onFinish={handleFinish} form={form}>
                <Form.Item name="username" rules={[{ required: true }]}>
                  <Input type="text" placeholder="username" size="large" />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true }]}>
                  <Input type="password" placeholder="password" size="large" />
                </Form.Item>
                <Form.Item style={{ textAlign: "center", marginBottom: "0px" }}>
                  <Button type="primary" htmlType="submit" {...btnAttrs}>
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
      <Footer>
        <span style={{ textAlign: "center", display: "block" }}>
          Willowtree {year}
        </span>
      </Footer>
    </Layout>
  );
};

LoginScreen.defaultProps = {
  loading: false,
};

LoginScreen.propTypes = {
  loading: PropTypes.bool,
  login: PropTypes.func.isRequired,
};

export default flowRight([
  connect(
    ({ app }) => ({
      loading: app.loading,
    }),
    (dispatch) => ({
      ...bindActionCreators({ login: userLogin }, dispatch),
    })
  ),
])(LoginScreen);
