/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { find, get } from 'lodash';
import Spin from 'antd/es/spin';

import './style.css';

const FilterTags = ({
  items, setFiltersFunc, currentFilters, fetch, count, loading,
}) => {
  const isActive = (key) => get(currentFilters, key, false) === true;
  const getClass = (key) => ['filter-tag', 'active'].filter((c) => c !== 'active' || isActive(key)).join(' ');

  useEffect(() => {
    // load the status count
    fetch();
  }, [items]);

  return (
    <div className="filter-tags-container">
      <div className={getClass('Ny')} onClick={() => setFiltersFunc({ Ny: true })}>
        <Spin spinning={loading}>
          <span className="filter-tag-label">Ny</span>
          <span className="filter-tag-value">
            {(find(count, (o) => o._id === 'Ny') || { count: 0 }).count}
          </span>
        </Spin>
      </div>

      <div className={getClass('Pakkes')} onClick={() => setFiltersFunc({ Pakkes: true })}>
        <Spin spinning={loading}>
          <span className="filter-tag-label">Pakkes</span>
          <span className="filter-tag-value">
            {(find(count, (o) => o._id === 'Pakkes') || { count: 0 }).count}
          </span>
        </Spin>
      </div>

      <div className={getClass('Pakket')} onClick={() => setFiltersFunc({ Pakket: true })}>
        <Spin spinning={loading}>
          <span className="filter-tag-label">Pakket</span>
          <span className="filter-tag-value">
            {(find(count, (o) => o._id === 'Pakket') || { count: 0 }).count}
          </span>
        </Spin>
      </div>

      <div className={getClass('Snooze')} onClick={() => setFiltersFunc({ Snooze: true })}>
        <Spin spinning={loading}>
          <span className="filter-tag-label">Snooze</span>
          <span className="filter-tag-value">
            {(find(count, (o) => o._id === 'Snooze') || { count: 0 }).count}
          </span>
        </Spin>
      </div>

      <div className={getClass('Rest')} onClick={() => setFiltersFunc({ Rest: true })}>
        <Spin spinning={loading}>
          <span className="filter-tag-label">Rest</span>
          <span className="filter-tag-value">
            {(find(count, (o) => o._id === 'Rest') || { count: 0 }).count}
          </span>
        </Spin>
      </div>
    </div>
  );
};

FilterTags.defaultProps = {
  items: {},
};

FilterTags.propTypes = {
  items: PropTypes.shape({}),
  setFiltersFunc: PropTypes.func.isRequired,
  currentFilters: PropTypes.shape({}).isRequired,
  fetch: PropTypes.func.isRequired,
  count: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default FilterTags;
