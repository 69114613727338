import { STEPS } from './constant';

export const REQUEST_PRINTING_START = '@stage/request_printing_start';
export const REQUEST_PRINTING_END = '@stage/request_printing_end';
export const REQUEST_PRINTING_MSG_START = '@stage/request_printing_msg_start';
export const REQUEST_PRINTING_MSG_END = '@stage/request_printing_msg_end';
export const SET_STEP = '@stage/set_step';
export const REINIT_STAGE = '@stage/reinit';
export const SET_STAGED_ORDER = '@stage/set_staged_order';
export const STAGE_FETCH_ORDER_START = '@stage/fetch_order_start';
export const STAGE_FETCH_ORDER_END = '@stage/fetch_order_end';
export const SHOW_SIDER = '@stage/show_sider';
export const HIDE_SIDER = '@stage/hide_sider';

export const setStagedOrder = (order) => ({
  type: SET_STAGED_ORDER,
  order,
});

export const requestPrint = (id, options, cb) => ({
  type: REQUEST_PRINTING_START,
  id,
  options,
  cb,
});

export const requestPrintMsg = (messages, cb) => ({
  type: REQUEST_PRINTING_MSG_START,
  messages,
  cb,
});

export const setStepRest = () => ({
  type: SET_STEP,
  step: STEPS.REST_NEEDED,
});

export const setStepToDocumentsPrinted = () => ({
  type: SET_STEP,
  step: STEPS.DOCUMENTS_PRINTED,
});

export const reinitStage = () => ({
  type: REINIT_STAGE,
});

export const fetchOrder = (id) => ({
  type: STAGE_FETCH_ORDER_START,
  id,
});

export const showSiderAction = () => ({
  type: SHOW_SIDER,
});

export const hideSiderAction = () => ({
  type: HIDE_SIDER,
});
