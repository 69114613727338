/* eslint-disable no-param-reassign */
const compression = {

};

/**
 * Adapter for localStorage
 *
 * @param key
 * @param options
 *
 * @constructor
 */
function DataStorage(key, options) {
  options = options || {};
  this.data = {};
  this.key = key || '__sg';
  this.options = this.merge({
    compression: false,
    split: '/',
    expireOn: false,
  }, options);
  this.pull();
}

DataStorage.prototype = {
  askCompression(withCompression, withoutCompression, value) {
    const c = this.options.compression ? compression[this.options.compression] : undefined;

    if (typeof c === 'function') {
      value = [value];
      value.unshift(c());
      withCompression.apply(this, value);
    } else {
      withoutCompression.apply(this, [value]);
    }
  },

  merge(a, b) {
    return { ...a, ...b };
  },

  initWith(data) {
    this.flush();
    this.data = data;

    return this.push();
  },

  /**
  * Grab data from native localStorage
  */
  pull() {
    let stored = null;

    this.askCompression((zip) => {
      stored = zip.decompress(localStorage.getItem(this.key));
    }, () => {
      stored = localStorage.getItem(this.key);
    }, stored);

    if (stored != null) {
      try {
        this.data = JSON.parse(stored);
      } catch (e) {
        console.warn('could not fetch cache data', e.message);
      }
    }

    return this;
  },

  /**
  * Push all data package in localStorage engine
  */
  push() {
    const str = JSON.stringify(this.data);

    this.askCompression((zip, value) => {
      localStorage.setItem(this.key, zip.compress(value));
    }, (value) => {
      localStorage.setItem(this.key, value);
    }, str);

    return this;
  },

  /**
  * Build a deep json object
  *
  * @param path
  * @param value
  * @returns {*}
  */
  buildTree(path, value) {
    const spl = new RegExp(this.options.split, 'ig');
    path = path.split(spl);

    let l = '';
    let r = '}';

    path.forEach((key, offset) => {
      l += `{"${key}":`;
      if (offset < path.length - 1) {
        r = `}${r}`;
      } else if (typeof value === 'object') {
        r = JSON.stringify(value) + r;
      } else if (typeof value === 'string') {
        r = `"${value}"${r}`;
      } else {
        r = value.toString() + r;
      }
    });

    l += r;

    // quick fix of JSON broken by newlines
    l = l.replace(/\r?\n|\r/g, '');

    return JSON.parse(l);
  },

  /**
  * Store value targeted by a deep key
  *
  * @param key
  * @param value
  * @returns {*}
  */
  set(key, value) {
    this.pull();

    this.data = {
      ...this.data,
      ...this.buildTree(key, value),
    };

    return this.push();
  },

  /**
  * Get data recursively by key, use split option
  *
  * @param key
  * @param defaultValue
  * @returns {*}
  */
  get(key, defaultValue) {
    const spl = new RegExp(this.options.split, 'ig');
    const path = key.split(spl);

    this.pull();
    let { data } = this;
    let result = defaultValue;

    path.forEach((k) => {
      if (typeof data[k] !== 'undefined') {
        data = data[k];
        result = data;
      } else result = defaultValue;
    });

    return result;
  },

  /**
  * Remove set of data
  *
  * @param key
  * @returns {*}
  */
  remove(key) {
    const spl = new RegExp(this.options.split, 'ig');
    const path = key.split(spl);

    if (path.length > 1) {
      const kdel = path.reverse().shift();
      key = path.reverse().join(this.options.split);

      const temp = this.recursive(key);
      if (typeof temp[kdel] !== 'undefined') {
        delete temp[kdel];
      }
    } else if (typeof this.data[key] !== 'undefined') {
      delete this.data[key];
    }

    return this.push();
  },

  /**
  * Reset all data
  */
  flush() {
    this.askCompression((zip, value) => {
      localStorage.setItem(this.key, zip.compress(value));
    }, (value) => {
      localStorage.setItem(this.key, value);
    }, JSON.stringify({}));

    this.data = {};

    return this;
  },

  /**
  * Retrieve all stored data
  *
  * @returns {{}|*}
  */
  fetchAll() {
    this.pull();

    return this.data;
  },
};

export default DataStorage;

export const storage = new DataStorage('__packingapp', { split: '/' });
