export const STEPS = {
  INITIAL: 'packing',
  DOCUMENTS_PRINTED: 'documents_are_printed',
  REST_NEEDED: 'rest',
};

export default {
  order: null,
  step: STEPS.INITIAL,
  loading: false,
  siderShown: false,
};
