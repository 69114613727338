/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-script-url */
import React from "react";
import PropTypes from "prop-types";
import { flowRight, get } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, Button, Typography, Skeleton } from "antd";
import { MessageOutlined, GiftOutlined } from "@ant-design/icons";
import Moment from "moment";
import { DateValue } from "../../helpers/date";
import OrderDetails from "./OrderDetails";
import {
  getLeveringsName,
  getItemsCount,
  getLogoByMethod,
} from "../../helpers/order";

import { storage } from "../../helpers/storage";

import "../../less/OrderItem.less";

const hasInnpakking = (order) => {
  const varelinjer = get(order, "values.varelinjer", {});

  return Object.keys(varelinjer).indexOf("50000") >= 0;
};

const OrderItem = ({
  history,
  onDetails,
  order,
  updateFn,
  createActFn,
  printFn,
}) => {
  const handleStartClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const userName = get(storage.get("user", {}), "username", "");
    updateFn(order._id, {
      $set: {
        status: "Pakkes",
        "values.status": "Pakkes",
        "values.pakket_av": userName,
        "values.pack_start": Moment().utc().toDate(),
        "values.pack_end": null,
      },
    });
    const payload = {
      creator: userName,
      activity: "Status updated to Pakkes",
      date: Moment().utc().toISOString(),
      order_sku: get(order, "values.number", ""),
    };
    createActFn(payload);
    history.push(`/pakk/${order._id}`);
  };

  const handleStatusChange = (e, status) => {
    e.stopPropagation();
    e.preventDefault();
    const userName = get(storage.get("user", {}), "username", "");
    updateFn(order._id, {
      $set: {
        status,
        "values.status": status,
      },
    });
    const payload = {
      creator: userName,
      activity: `Status updated to ${status}`,
      date: Moment().utc().toISOString(),
      order_sku: get(order, "values.number", ""),
    };
    createActFn(payload);
  };

  const handlePrint = (e) => {
    e.stopPropagation();
    e.preventDefault();
    printFn(order._id || order.id);
  };

  const renderButtons = () => {
    const currentStatus = get(order, "values.status", null);

    if (!currentStatus) {
      return [];
    }

    switch (currentStatus) {
      case "Ny":
        return [
          <Button onClick={handleStartClick} size="large" type="primary">
            Start Pakking
          </Button>,
        ];

      case "Pakkes":
        return [
          <Button
            loading={get(order, "loading", false)}
            onClick={(e) => handleStatusChange(e, "Ny")}
            size="large"
            type="danger"
          >
            Sett NY
          </Button>,
          <Button
            loading={get(order, "loading", false)}
            onClick={(e) => handleStatusChange(e, "Rest")}
            size="large"
            type="primary"
          >
            Sett REST
          </Button>,
        ];

      case "Rest":
        return [
          <Button onClick={handleStartClick} size="large" type="primary">
            PAKK
          </Button>,
          <Button
            loading={get(order, "loading", false)}
            onClick={(e) => handleStatusChange(e, "Ny")}
            size="large"
            type="danger"
          >
            Sett NY
          </Button>,
        ];

      case "Pakket":
        return [
          <Button
            loading={get(order, "printing", false)}
            onClick={handlePrint}
            size="large"
            type="danger"
          >
            Print
          </Button>,
        ];

      default:
        return [];
    }
  };

  return (
    <div className="order-item">
      <Card
        actions={[
          ...renderButtons(),
          <div
            className="order-item-lightbox-invoke"
            onClick={onDetails(order)}
            style={{ textAlign: "right", paddingRight: "1%" }}
          >
            {getItemsCount(order.values)} produkter
          </div>,
        ]}
        style={{ cursor: "pointer" }}
        onClick={onDetails(order)}
      >
        <Card.Grid hoverable={false} style={{ width: "60%" }}>
          <Typography.Title level={4}>
            {getLeveringsName(order.values, true)}{" "}
            {get(order, "values.kommentar", "") !== "" ? (
              <MessageOutlined
                size="large"
                // onClick={onDetails(order)}
                style={{ cursor: "pointer", paddingLeft: "1%", fontSize: 20 }}
              />
            ) : null}{" "}
            {hasInnpakking(order) ? (
              <GiftOutlined
                size="large"
                // onClick={onDetails(order)}
                style={{ cursor: "pointer", paddingLeft: "1%", fontSize: 20 }}
              />
            ) : null}
          </Typography.Title>
          <Typography.Text code>{get(order, "values.number")}</Typography.Text>
          <Typography.Text type="secondary">
            <DateValue value={get(order, "values.ordreDato")} />
          </Typography.Text>
        </Card.Grid>
        <Card.Grid
          hoverable={false}
          style={{ width: "40%", textAlign: "right", paddingRight: "1%" }}
          className="order-item-logo"
        >
          <img
            alt={get(order, "values.leveringstype")}
            src={getLogoByMethod(get(order, "values.leveringstype"))}
          />
        </Card.Grid>
      </Card>
    </div>
  );
};

OrderItem.propTypes = {
  history: PropTypes.any.isRequired,
  onDetails: PropTypes.any.isRequired,
  updateFn: PropTypes.func.isRequired,
  createActFn: PropTypes.func.isRequired,
  printFn: PropTypes.func.isRequired,
  order: PropTypes.shape({
    id: PropTypes.string,
    values: PropTypes.any,
  }).isRequired,
};

const OrderItemSkeleton = () => (
  <div className="order-item">
    <Card
      actions={[
        <Skeleton.Input size="large" active style={{ width: 200 }} />,
        <span style={{ float: "right", paddingRight: "1%" }}>
          <Skeleton.Input size="small" active style={{ width: 100 }} />
        </span>,
      ]}
    >
      <Card.Grid hoverable={false} style={{ width: "60%" }}>
        <Skeleton.Input active size="default" style={{ width: 200 }} />
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={{ width: "40%", textAlign: "right", paddingRight: "1%" }}
        className="order-item-logo"
      >
        <Skeleton.Input active size="default" style={{ width: 100 }} />
      </Card.Grid>
    </Card>
  </div>
);

export default {
  Item: flowRight([connect(), withRouter])(OrderItem),
  Skeleton: OrderItemSkeleton,
  Details: OrderDetails,
};
