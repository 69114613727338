/* eslint-disable import/prefer-default-export */
import React from "react";
import update from "immutability-helper";
import { get, chain, keyBy } from "lodash";
import { httpPutOrders } from "../api/orders";

export const getLeveringsName = (values, html = false) => {
  const htmlName = get(values, "leveringsAdresse", "")
    .replace("<p>", "")
    .replace("</p>", "")
    .split(/<br ?\/>/gi)
    .shift()
    .replace("(navn)", "");

  return html ? (
    <span dangerouslySetInnerHTML={{ __html: htmlName }} />
  ) : (
    htmlName
  );
};

export const getLogoByMethod = (method) => {
  switch (method) {
    case "Posten/Bring":
    case "Sporingspakke":
      return "/assets/images/etsy_bring.png";
    case "RFID":
      return "/assets/images/etsy_rfid.jpg";
    case "Kaffekruset":
      return "/assets/images/etsy_kaffekruset.jpg";
    default:
      return null;
  }
};

// filter out the items in state so that
// it only shows items with status Ny by default
export const filterItems = (items, filters = ["Ny"]) => {
  const filteredItems = keyBy(
    Object.keys(items)
      .filter((k) => filters.includes(get(items, `[${k}].values.status`, "")))
      .map((k) => get(items, `[${k}]`, null)),
    "_id"
  );

  return update(items, { $set: filteredItems });
};

export const getItemsCount = (values) =>
  chain(values)
    .get("ordre")
    .map((v) => v)
    .sumBy("amount")
    .value();

export const handleChangeOrderStatus = (status, orderId, hooks) => {
  if (hooks.before && typeof hooks.before === "function") {
    hooks.before();
  }
  httpPutOrders(orderId, {
    $set: { "values.status": status },
  })
    .then((results) => {
      if (hooks.done && typeof hooks.done === "function") {
        hooks.done(results);
      }
    })
    .catch((exception) => {
      if (hooks.error && typeof hooks.error === "function") {
        hooks.error(exception);
      }
    })
    .finally(() => {
      if (hooks.after && typeof hooks.after === "function") {
        hooks.after();
      }
    });
};
