/* eslint-disable func-names */
import {
  all, fork, takeLatest, put,
} from 'redux-saga/effects';
import {
  REQUEST_PRINTING_START, REQUEST_PRINTING_END,
  REQUEST_PRINTING_MSG_START,
  STAGE_FETCH_ORDER_START, STAGE_FETCH_ORDER_END,
  // SET_STEP,
} from './action';
// import { STEPS } from './constant';
import { APP_FAILURE } from '../app/action';
import { httpPrintOrderDocuments, httpUpdateAmounts, httpFindOrder, httpPrintMsgLabel } from '../../api/orders';
import { handleError } from '../error';

function* handlePrinting() {
  yield takeLatest(REQUEST_PRINTING_START, function* ({ id, options, cb }) {
    try {
      yield httpUpdateAmounts(id, { delivered: options.orderAmount });

      const { data } = yield httpPrintOrderDocuments(id);
      /* yield put({
        type: SET_STEP,
        step: STEPS.DOCUMENTS_PRINTED,
      }); */
      if (typeof cb === 'function') { cb(null, data); }
    } catch (e) {
      yield handleError(APP_FAILURE, e);
    } finally {
      yield put({
        type: REQUEST_PRINTING_END,
      });
    }
  });
}

function* handlePrintingMsg() {
  yield takeLatest(REQUEST_PRINTING_MSG_START, function* ({ messages, cb }) {
    try {
      const { data } = yield httpPrintMsgLabel({messages});

      if (typeof cb === 'function') { cb(null, data); }
    } catch (e) {
      yield handleError(APP_FAILURE, e);
    } 
    /* finally {
      yield put({
        type: REQUEST_PRINTING_END,
      });
    } */
  });
}

function* handleFetchOrder() {
  yield takeLatest(STAGE_FETCH_ORDER_START, function* ({ id }) {
    try {
      const { data } = yield httpFindOrder(id);
      yield put({
        type: STAGE_FETCH_ORDER_END,
        order: data,
      });
    } catch (e) {
      yield handleError(APP_FAILURE, e);
      yield put({
        type: STAGE_FETCH_ORDER_END,
        order: null,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(handlePrinting),
    fork(handleFetchOrder),
    fork(handlePrintingMsg),
  ]);
}
