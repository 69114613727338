/* eslint-disable default-case */
import { isObject } from 'lodash';
import { storage } from '../helpers/storage';
import { history } from './history';
import {
  USER_LOGIN_DONE, USER_LOGIN_REQ, USER_LOGIN_FAILED, USER_LOGOUT,
} from './user/action';
import { ORDER_FETCH_START, ORDER_FETCH_END } from './orders/action';
import {
  APP_LOADING, APP_LOADING_OFF, APP_FAILURE, APP_NOTIFY,
} from './app/action';

export default ({ dispatch }) => (next) => (action) => {
  // before the dispatch action
  switch (action.type) {
    case USER_LOGIN_REQ:
    case ORDER_FETCH_START:
      dispatch({
        type: APP_LOADING,
      });
      break;
    case USER_LOGIN_DONE:
    case USER_LOGIN_FAILED:
    case ORDER_FETCH_END:
      dispatch({
        type: APP_LOADING_OFF,
      });
      break;
  }

  const nextAction = next(action);

  // after the dispatch action
  switch (action.type) {
    case USER_LOGIN_DONE:
      // persist data in localStorage
      storage.set('user', {
        ...action.user,
        token: action.token,
        authenticated: true,
      });
      history.push('/kundeordre');
      break;
    case USER_LOGOUT:
      // remove all persisted data in localStorage
      storage.flush();
      history.push('/login');
      break;
    case APP_FAILURE:
      dispatch({
        type: APP_NOTIFY,
        severity: 'error',
        text: isObject(action.error) ? action.error.message : action.error,
      });
      break;
  }

  return nextAction;
};
