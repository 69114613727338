import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { flowRight } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Moment from "moment";
import {
  Button,
  Layout,
  Row,
  Col,
  Drawer,
  Menu,
  Divider,
  Checkbox,
  Form,
} from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { userLogout } from "../../redux/user/action";
import { setFilters, fetchStatusCount } from "../../redux/app/action";
import FilterTags from "./FilterTags";

import config from "../../config";
import "../../less/Layout.less";

const UserLayout = ({
  user,
  history,
  logout,
  children,
  setTheFilters,
  orderItems,
  filters,
  fetchCountStatus,
  statusCount,
  loadingCount,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const drawerContainer = useRef(null);

  const hideDrawer = () => {
    setDrawerVisible(false);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClickMenu = (path) => () => {
    history.push(path);
    hideDrawer();
  };

  useEffect(() => {
    if (!user.authenticated) {
      history.push("/login");
    }
  }, []);

  if (!user.authenticated) {
    return null;
  }

  const [form] = Form.useForm();
  const onSubmit = (values) => {
    const keyWithValues = Object.keys(values).filter((key) => !!values[key]);
    let filtersToSet = { Ny: true };
    // forbid no filters
    // force to Ny when no filters are set
    if (keyWithValues.length > 0) {
      filtersToSet = values;
    } else {
      form.setFieldsValue(filtersToSet);
    }
    setTheFilters(filtersToSet);
  };

  return (
    <>
      <Layout className="app-layout">
        <Layout.Header className="app-header">
          <Row justify="center" className="app-wrapper">
            <Col
              xs={24}
              className="right"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FilterTags
                items={orderItems}
                setFiltersFunc={setTheFilters}
                currentFilters={filters}
                fetch={fetchCountStatus}
                count={statusCount}
                loading={loadingCount}
              />
              <Button
                style={{ marginLeft: "2px" }}
                onClick={showDrawer}
                type="link"
                icon={
                  <img
                    className="app-logo"
                    alt="Willowtree"
                    src={config.logo}
                  />
                }
                size="large"
              />
            </Col>
          </Row>
        </Layout.Header>
        <Layout.Content className="app-content">
          <Row justify="center" className="app-wrapper">
            <Col xs={24} md={24}>
              {children}
            </Col>
          </Row>
        </Layout.Content>
        <Layout.Footer className="app-footer">
          <span style={{ textAlign: "center", display: "block" }}>
            Willowtree {Moment().year()}
          </span>
        </Layout.Footer>
      </Layout>
      <div ref={drawerContainer}>
        <Drawer
          placement="right"
          closable={false}
          onClose={hideDrawer}
          visible={drawerVisible}
          getContainer={drawerContainer}
          destroyOnClose
        >
          <Menu className="app-menu">
            <Menu.Item onClick={onClickMenu("/kundeordre")}>
              Kundeordre
            </Menu.Item>
            <Menu.Item style={{ height: "auto" }}>
              <Form onFinish={onSubmit} form={form}>
                <Form.Item name="Ny" valuePropName="checked" initialValue>
                  <Checkbox onChange={() => form.submit()}>Ny</Checkbox>
                </Form.Item>
                <Form.Item name="Pakkes" valuePropName="checked">
                  <Checkbox onChange={() => form.submit()}>Pakkes</Checkbox>
                </Form.Item>
                <Form.Item name="Rest" valuePropName="checked">
                  <Checkbox onChange={() => form.submit()}>Rest</Checkbox>
                </Form.Item>
                <Form.Item name="Pakket" valuePropName="checked">
                  <Checkbox onChange={() => form.submit()}>Pakket</Checkbox>
                </Form.Item>
              </Form>
            </Menu.Item>
            <Menu.Item onClick={onClickMenu("/innkjopsordre")}>
              Innkjøpsordre
            </Menu.Item>
            <Menu.Item disabled>
              <Divider />
            </Menu.Item>
            <Menu.Item onClick={logout} icon={<LogoutOutlined />}>
              Logout
            </Menu.Item>
          </Menu>
        </Drawer>
      </div>
    </>
  );
};

UserLayout.displayName = "UserLayout";

UserLayout.defaultProps = {
  user: {
    authenticated: false,
  },
};

UserLayout.propTypes = {
  history: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  logout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    authenticated: PropTypes.bool,
  }),
  setTheFilters: PropTypes.func.isRequired,
  orderItems: PropTypes.shape({}).isRequired,
  filters: PropTypes.shape({}).isRequired,
  fetchCountStatus: PropTypes.func.isRequired,
  statusCount: PropTypes.shape({}).isRequired,
  loadingCount: PropTypes.bool.isRequired,
};

export default flowRight([
  connect(
    ({ user, orders, app }) => ({
      user,
      orderItems: orders.items,
      filters: app.filters,
      statusCount: app.statusCount,
      loadingCount: app.loadingStatusCount,
    }),
    (dispatch) => ({
      ...bindActionCreators(
        {
          logout: userLogout,
          setTheFilters: setFilters,
          fetchCountStatus: fetchStatusCount,
        },
        dispatch
      ),
    })
  ),
  withRouter,
])(UserLayout);
