/* eslint-disable func-names */
import {
  all, fork, takeEvery, takeLatest, put,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { httpGetStatusCount } from '../../api/orders';
import { APP_NOTIFY, APP_FETCH_STATUS_COUNT_START, APP_FETCH_STATUS_COUNT_END } from './action';

export function* handleNotification() {
  yield takeEvery(APP_NOTIFY, function* ({ text, severity }) {
    yield notification[severity]({
      // title: 'Application Error',
      message: text,
    });
  });
}

function* handleStatusCount() {
  yield takeLatest(APP_FETCH_STATUS_COUNT_START, function* () {
    const results = yield httpGetStatusCount();

    yield put({ type: APP_FETCH_STATUS_COUNT_END, payload: results.data });
  });
}

export default function* rootSaga() {
  yield all([
    fork(handleNotification),
    fork(handleStatusCount),
  ]);
}
